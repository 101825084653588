/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import { ResponsiveImage, ModalPortal } from '@/components';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import CarouselPagination from '@/components/general/CarouselPagination';
import '@egjs/react-flicking/dist/flicking.css';
import Arrow from 'assets/arrowRight.svg';
import Close from 'assets/close.svg';
import { useTranslation } from 'next-i18next';
import { fixColor, isColorDark } from '@/utils/color-utils.js';

const MediaLightboxCarousel = ({
  isOpen,
  setIsOpen,
  moduleName,
  backgroundColour,
  closeOnBgClick = false,
  slides,
  imageWidths,
  imageHeights,
  imageContainerWidthClassName = 'w-[332px] md:w-[688px] lg:w-[1016px]',
  imageContainerHeightClassName = 'h-[163px] md:h-[328px] lg:h-[485px]',
}) => {
  const carousel = useRef();
  const [showCarousel, setShowCarousel] = useState(false);
  const [height, setHeight] = useState(null);
  // const [closeDelay, setCloseDelay] = useState(false);
  // const [panelCount, setPanelCount] = useState(0);
  const { t } = useTranslation('common');
  const [activeIndex, setActiveIndex] = useState(0);
  const dark = isColorDark(fixColor(backgroundColour));

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  const willChange = (e) => {
    setActiveIndex(e.index);
  };

  // const onReady = () => {
  //   setPanelCount(carousel.current.panelCount);
  // };

  const initAndMoveTo = async (step) => {
    try {
      if (carousel.current) {
        await carousel.current.init();
        await carousel.current.moveTo(step);
        setActiveIndex(step);
        setShowCarousel(true);
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  useEffect(() => {
    const _setHeight = () => {
      setHeight(window.innerHeight);
    };

    _setHeight();
    window.addEventListener('resize', _setHeight);

    return () => {
      window.removeEventListener('resize', _setHeight);
    };
  }, []);

  useEffect(() => {
    if (isOpen !== null) {
      initAndMoveTo(isOpen);
    }
  }, [isOpen]);

  const close = () => {
    carousel.current.destroy();
    setShowCarousel(false);
    setTimeout(() => {
      setIsOpen(null);
    }, 1000);
  };

  return (
    <ModalPortal>
      {isOpen ? (
        <div
          className={`media-lightbox-carousels fixed top-0 z-50 w-screen h-dvh lg:h-screen flex justify-center items-center flex-col ${
            showCarousel
              ? 'disable-scroll opacity-100 transition-all duration-1000'
              : 'opacity-0 transition-all duration-1000'
          }`}
        >
          <div
            className="absolute opacity-75 bg-black w-screen h-screen"
            onClick={() => {
              if (closeOnBgClick) close();
            }}
          ></div>
          <button
            className={`absolute right-6 border-none md:top-12 md:right-10 lg:right-[52px] btn text invert border circle p-0 w-10 h-10 z-10 ${moduleName === 'MediaMasonryGridModule' ? 'top-6' : 'top-10'}`}
            onClick={() => {
              close();
            }}
          >
            <Close className="stroke-black w-10 h-10" />
          </button>

          <div className="relative">
            <Flicking
              ref={carousel}
              align="center"
              useResizeObserver={true}
              horizontal={true}
              circular={true}
              preventDefaultOnDrag
              // onReady={onReady}
              onWillChange={willChange}
              className={`m-auto h-full ${
                height > 768
                  ? imageContainerWidthClassName
                  : `w-[332px] md:w-[688px] lg:w-full lg:max-w-[70vw] !overflow-hidden`
              }`}
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`flex flex-col w-full ${moduleName !== 'MediaMasonryGridModule' ? 'justify-center items-start' : 'justify-start items-center'}`}
                >
                  <div
                    className={`ml-px h-fit flex items-end mb-6 lg:mb-6 text-brand-soft-white font-montserrat text-xs leading-normal tracking-title-m uppercase font-medium lg:text-sm lg:leading-normal lg:tracking-button-reg ${moduleName === 'MediaMasonryGridModule' ? 'w-full justify-center' : ''}`}
                  >
                    {index + 1} of {slides.length}
                  </div>
                  <div
                    className={`${
                      moduleName !== 'MediaMasonryGridModule'
                        ? `w-full ${
                            height > 768
                              ? imageContainerHeightClassName
                              : `h-[163px] md:h-[328px] lg:h-full lg:max-h-[50vh]`
                          }`
                        : `h-fit max-h-[55vh] md:max-h-[60vh] m-auto shrink-0 ${slide.caption || slide.imageAlt ? 'mb-0' : ''}`
                    }`}
                  >
                    <ResponsiveImage
                      image={slide.image ? slide.image : slide}
                      widths={moduleName !== 'MediaMasonryGridModule' ? imageWidths : {}}
                      heights={moduleName !== 'MediaMasonryGridModule' ? imageHeights : {}}
                    />
                  </div>
                  {(slide.imageAlt || slide.caption) && (
                    <div
                      className={`h-fit w-full mt-4 ${moduleName === 'MediaMasonryGridModule' ? 'text-center mb-auto' : ''}`}
                    >
                      {slide.imageAlt && (
                        <p className="font-montserrat uppercase text-brand-soft-white text-sm not-italic font-semibold leading-normal tracking-title-s mb-1">
                          {slide.imageAlt}
                        </p>
                      )}

                      {slide.caption && (
                        <p className="font-montserrat text-brand-soft-white text-sm not-italic font-normal leading-6">
                          {slide.caption}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </Flicking>
            {moduleName !== 'MediaMasonryGridModule' && (
              <CarouselPagination
                dark={dark}
                current={activeIndex}
                total={slides.length}
                className="mx-auto absolute top-[2%] right-0"
                moduleName={moduleName}
              />
            )}
          </div>

          <div
            className={`w-fit h-fit flex gap-4 md:gap-6 lg:w-full lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:max-w-[1208px] lg:justify-between ${moduleName === 'MediaMasonryGridModule' ? 'mb-0 mt-4 lg:mt-0' : 'my-10'}`}
          >
            <button
              className="btn text-light hover:!bg-brand-soft-white/25 lg:hover:!bg-grey6 border-none circle bg-brand-soft-white/25 p-0 w-10 h-10 z-10 group/next"
              onClick={move}
              aria-label={t('general.$previous')}
            >
              <Arrow className="rotate-180 group-hover/next:lg:!stroke-brand-black" />
            </button>

            <button
              className="btn text-light hover:!bg-brand-soft-white/25 lg:hover:!bg-grey6 border-none circle bg-brand-soft-white/25 p-0 w-10 h-10 z-10 group/prev"
              onClick={() => move(1)}
              aria-label={t('general.$next')}
            >
              <Arrow className="group-hover/prev:lg:!stroke-brand-black" />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </ModalPortal>
  );
};

export default MediaLightboxCarousel;
